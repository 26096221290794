<template>
  <div id="user-list">
    <v-snackbar v-model="isSnackbarVisible" :color="snackbar.color" :timeout="snackbar.timeout" top right auto-height>
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>{{ snackbar.icon == 'success' ? icons.mdiCheckBold : icons.mdiCheckBold }}
        </v-icon>

        <v-layout column>
          <div>
            <strong>{{ snackbar.title }}</strong>
          </div>
          <div>{{ snackbar.text }}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>

    <v-dialog v-model="isDialogVisible" max-width="600px">
      <v-card>
        <v-form @submit.prevent="saveData">
          <v-card-title>
            <span class="headline">{{ modalTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6" sm="6" md="6">
                  <v-text-field label="Nombre completo" dense v-model="row.nombre"
                    :rules="[() => !!row.nombre || 'Este campo es requerido.']" :error-messages="errorMessages"
                    ref="nombre" required></v-text-field>
                </v-col>
                <!-- <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  label="Middle Name"
                  dense
                  hint="example of helper text only on focus"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  label="Last Name"
                  dense
                  persistent-hint
                ></v-text-field>
              </v-col> -->
                <v-col cols="6">
                  <v-text-field label="Email" dense v-model="row.email"></v-text-field>
                </v-col>
                <!-- <v-col cols="6">
                  <v-text-field dense label="Celular" type="text" v-model="row.celular"></v-text-field>
                </v-col> -->
                <v-col cols="6">
                  <v-text-field dense label="Clave" type="password" v-model="row.clave"></v-text-field>
                </v-col>
                <!-- <v-col cols="6">
                  <input type="file" id="file1" ref="file1" v-on:change="onFileSelected('cliente', 1)" />
                  <v-text-field dense v-model="row.url_img"></v-text-field>
                </v-col> -->
                <v-col cols="12" sm="6">
                  <v-img :src="row.url_img" max-height="80px" alt="logo" contain class="me-3"></v-img>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select v-if="commerceList.length > 0" v-model="row.comercio_id" :items="commerceList"
                    label="Comercio" dense item-text="nombre_comercio" item-value="id"></v-select>
                </v-col>

                <v-col cols="6" sm="6">
                  <v-select v-model="row.estado" :items="statusList" label="Estado" dense item-text="nombre"
                    item-value="nombre"></v-select>
                </v-col>
                <v-col cols="6">
                  <v-text-field dense label="Código cargotrans" type="text"
                    v-model="row.codigo_cargotrans"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field dense label="Código correos Nicaragua" type="text"
                    v-model="row.codigo_correonic"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field dense label="Celular Tigo" type="text" v-model="row.celular_tigo"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field dense label="Celular Claro" type="text" v-model="row.celular_claro"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field dense label="Teléfono convencional" type="text"
                    v-model="row.telefono_convencional"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field dense label="Rubro" type="text" v-model="row.rubro"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field dense label="facebook" type="text" v-model="row.facebook"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field dense label="instagram" type="text" v-model="row.instagram"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field dense label="whatsapp" type="text" v-model="row.whatsapp"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field dense label="Banco cuenta Bancaria" type="text"
                    v-model="row.banco_nombrebanco"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field dense label="Numero cuenta Bancaria" type="text"
                    v-model="row.banco_numerocuenta"></v-text-field>
                </v-col>
                <v-col cols="6" sm="6">
                  <v-select v-model="row.banco_moneda" :items="monedaLista" label="Moneda" dense item-text="moneda"
                    item-value="moneda"></v-select>
                </v-col>
                <!-- <v-col
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  :items="['Skiing', 'Ice hockey', 'Soccer', 'Basketball', 'Hockey', 'Reading', 'Writing', 'Coding', 'Basejump']"
                  label="Interests"
                  dense
                  multiple
                ></v-autocomplete>
              </v-col> -->
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" outlined @click="isDialogVisible = false"> Cerrar </v-btn>
            <v-btn v-if="user.rol == 1 || user.rol == 2" type="submit" color="success">
              <!-- @click="isDialogVisible = false" -->
              Guardar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- <v-card>
      <v-card-title>
        Nutrition
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
      </v-card-title>
      <v-data-table :headers="tableColumns" :items="items" :search="search"></v-data-table>
    </v-card> -->

    <!-- list filters -->
    <h2 class="m-2">CLIENTES</h2>
    <v-card>
      <v-btn v-if="user.rol == 1 || user.rol == 2" color="info" class="m-4" @click="showModal(1)">
        <v-icon center>{{ icons.mdiPlusCircle }} </v-icon>
        &nbsp;Cliente
      </v-btn>

      <download-excel class="vv-btn" :data="items" :fields="json_fields" worksheet="My Worksheet"
        name="Usuarios-yaqui.xls">
        Descargar datos de clientes
      </download-excel>
      <v-card-title>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Click aqui para buscar..." single-line
          hide-details></v-text-field>
      </v-card-title>
      <!-- 
      <v-text-field
        class="ml-2"
        v-model="search"
        append-icon="mdi-magnify"
        label="click aqui para buscar..."
        single-line
        hide-details
      ></v-text-field> -->
      <!-- table -->
      <!-- v-model="selectedRows" -->
      <!-- :options.sync="options" -->
      <v-data-table :loading="loading" :headers="tableColumns" :items="items" :search="search">
        <!-- name -->
        <template #[`item.fullName`]="{ item }">
          <div class="d-flex align-center">
            <v-avatar :color="item.avatar ? '' : 'primary'"
              :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'" size="32">
              <v-img v-if="item.avatar" :src="require(`@/assets/images/avatars/${item.avatar}`)"></v-img>
              <span v-else class="font-weight-medium">{{ avatarText(item.fullName) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link :to="{ name: 'apps-user-view', params: { id: item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none">
                {{ item.fullName }}
              </router-link>
              <small>@{{ item.username }}</small>
            </div>
          </div>
        </template>

        <!-- role -->
        <template #[`item.role`]="{ item }">
          <div class="d-flex align-center">
            <v-avatar size="30" :color="resolveUserRoleVariant(item.role)"
              :class="`v-avatar-light-bg ${resolveUserRoleVariant(item.role)}--text me-3`">
              <v-icon size="18" :color="resolveUserRoleVariant(item.role)">
                {{ resolveUserRoleIcon(item.role) }}
              </v-icon>
            </v-avatar>
            <span class="text-capitalize">{{ item.role }}</span>
          </div>
        </template>

        <!-- plan -->
        <template #[`item.currentPlan`]="{ item }">
          <span class="text-capitalize font-weight-semibold text--primary">{{ item.currentPlan }}</span>
        </template>

        <!-- status -->
        <template #[`item.status`]="{ item }">
          <v-chip small :color="resolveUserStatusVariant(item.status)"
            :class="`${resolveUserStatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize">
            {{ item.status }}
          </v-chip>
        </template>

        <!-- actions -->

        <template #[`item.acciones`]="{ item }">
          <!-- <input class="btn-primary" type="button" ></input> -->
          <button class="btn btn-green" @click="showModal(2, item)">
            <v-icon center style="color: white"> {{ icons.mdiPencil }} </v-icon>
          </button>

          &nbsp;
          <button v-if="user.rol == 1 || user.rol == 2" class="btn btn-red" @click="deleteData(item)">
            <v-icon center style="color: white"> {{ icons.mdiDeleteOutline }} </v-icon>
          </button>
          &nbsp;
        </template>

        <!-- <template #[`item.acciones`]="{ item }">
          <v-btn color="success" medium @click="showModal(2, item)">
            <v-icon center dark> {{ icons.mdiPencil }} </v-icon>
          </v-btn>
          &nbsp;
          <v-btn color="error" medium @click="deleteData(item)">
            <v-icon center dark> {{ icons.mdiDeleteOutline }} </v-icon>
          </v-btn>
        </template> -->
        <!-- 
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                :to="{name:'apps-user-view',params:{id:item.id}}"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item link>
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiCloudUploadOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template> -->
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mdiCheckBold, mdiDeleteOutline, mdiPencil, mdiPlusCircle } from '@mdi/js'

import { ref } from '@vue/composition-api'

import Vue from 'vue'

import JsonExcel from 'vue-json-excel'

Vue.component('downloadExcel', JsonExcel)

// sidebar
import { avatarText } from '@core/utils/filter'
// import UserListAddNew from './UserListAddNew.vue'

export default {
  data() {
    return {
      icons: { mdiDeleteOutline, mdiPencil, mdiPlusCircle, mdiCheckBold },
      items: [],
      table: 'clientes',
      tableColumns: [
        { text: 'id', value: 'id', sortable: true },
        { text: 'Nombre', value: 'nombre', sortable: true },
        { text: 'Celular', value: 'celular', sortable: true },
        { text: 'Email', value: 'email', sortable: true },
        { text: 'Estado', value: 'estado', sortable: true },
        { text: 'Acciones', value: 'acciones', sortable: false },

        // {id:0, name:}
      ],
      loading: false,
      totalItems: 0,
      isDialogVisible: false,
      row: {},
      modalTitle: 'Nuevo cliente',
      commerceList: [],
      formHasErrors: false,
      errorMessages: '',
      form: ref(null),
      valid: ref(true),
      isSnackbarVisible: false,
      message: '',
      messageColor: 'primary',
      icon: '',
      snackbar: {
        color: 'success',
        icon: 'check_circle',
        mode: 'multi-line',
        title: 'Success',
        text: 'That worked, hoorah.',
        visible: true,
      },
      statusList: [{ nombre: 'Activo' }, { nombre: 'Inactivo' }],
      file: null,
      filename1: null,
      json_fields: {
        nombre: 'nombre',
        email: 'email',
        celular: 'celular',
      },
      monedaLista: [{ moneda: 'Córdobas' }, { moneda: 'USD' }],
      search: '',
      user: {},
    }
  },
  async mounted() {
    this.user = JSON.parse(localStorage.getItem('user'))

    await this.getData()
    await this.getCommerce()
  },
  methods: {
    onFileSelected(type, item) {
      this.file1 = event.target.files[0]
      this.filename1 = Math.floor(Math.random() * 9999999999999 + 1)
      this.filename1 = type + '_' + this.filename1 + '.jpg'
    },
    async saveData() {
      try {
        //pendiente la validación del form
        // this.formHasErrors = false
        //let row = Object.assign({}, this.row)
        let row = { ...this.row }

        let uploadImg
        //this.$isLoading(true)
        let url_img = ''
        if (this.filename1 != null) {
          uploadImg = await this.$store.dispatch('onFileSelected', { filename: this.filename1, file: this.file1 })
          if (uploadImg == true) {
            console.log('----', uploadImg)
            row.url_img = 'https://appyaqui.com/recursosapps/img/' + this.filename1
          }
        }

        let res
        if (row.id) {
          //es update
          delete row.id
          res = await this.$store.dispatch('put', { path: this.table + '/modify/' + this.row.id, data: row })
        } else {
          res = await this.$store.dispatch('post', { path: this.table + '/new/', data: row })
          //es post
        }
        this.getData()
        this.snackbar = {
          color: 'success',
          icon: 'success',
          mode: 'multi-line',
          title: 'Muy bien!',
          text: 'Registro procesado correctamente!.',
          timeout: '2500',
        }

        this.isDialogVisible = false
        this.isSnackbarVisible = true
        console.log(res)
      } catch (error) {
        console.log(error)
      }
    },
    showModal(type, item) {
      if (type == 1) {
        this.modalTitle = 'Nuevo cliente'
        this.row = {}
      } else {
        this.row = item
        this.modalTitle = 'Editar cliente'
      }
      this.isDialogVisible = true
    },
    async getData() {
      try {
        this.loading = true
        let res = await this.$store.dispatch('get', { path: this.table + '/getAll/' })
        this.loading = false
        if (res.length > 0) {
          this.items = res
          this.totalItems = this.items.length
        }
      } catch (error) {
        this.items = []
        this.loading = false
        console.error('error', error)
      }
    },
    async getCommerce() {
      try {
        this.loading = true
        let res = await this.$store.dispatch('get', { path: 'comercios/getAll/' })
        this.loading = false
        if (res.length > 0) {
          console.log(res)
          this.commerceList = res
        }
      } catch (error) {
        this.commerceList = []
        this.loading = false
        console.error('error', error)
      }
    },
    async deleteData(item) {
      try {
        let txt
        if (confirm('¿Eliminar Registro?')) {
          this.loading = true
          let res = await this.$store.dispatch('delete', { path: this.table + '/' + item.id })
          await this.getData()
          this.loading = false
          txt = 'You pressed OK!'
          this.snackbar = {
            color: 'success',
            icon: 'success',
            mode: 'multi-line',
            title: 'Muy bien!',
            text: 'Registro elimiado correctamente!.',
            timeout: '2500',
          }
          this.isSnackbarVisible = true
        } else {
          //txt = 'You pressed Cancel!'
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
}

.btn-green {
  color: rgb(255, 255, 255) !important;
  background-color: #20b11b !important;
  border-color: #20b11b !important;
}

.btn-blue {
  color: #fff !important;
  background-color: #007bff !important;
  border-color: #007bff !important;
}

.btn-red {
  color: #fff !important;
  background-color: #ff0037 !important;
  border-color: #ff0037 !important;
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.vv-btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  background-color: rgb(48, 146, 48);
  color: white;
}
</style>
